import { Language } from 'interfaces/Language'
import { ParsedLocale } from './localization'
import { CountryCode } from 'interfaces/Country'

export function returnLanguageSwitcherLabelByCountryCode(countyCode: CountryCode) {
    switch (countyCode) {
        case CountryCode.Switzerland:
        case CountryCode.Germany:
        case CountryCode.Austria:
            return 'Deutschland'
        case CountryCode.UnitedStates:
            return 'United States'
        case CountryCode.UnitedKingdom:
            return 'United Kingdom'
        case CountryCode.France:
            return 'France'
        case CountryCode.Italy:
            return 'Italia'
        case CountryCode.Poland:
            return 'Polska'
        case CountryCode.Colombia:
            return 'Colombia'
        case CountryCode.Turkey:
            return 'Türkiye'
        case CountryCode.Spain:
            return 'España'
        case CountryCode.Mexico:
            return 'México'
        case CountryCode.Romania:
            return 'România'
        case CountryCode.Greece:
            return 'Ελλάδα'
        case CountryCode.Peru:
            return 'Perú'
        case CountryCode.Chile:
            return 'Chile'
        case CountryCode.Argentina:
            return 'Argentina'
        case CountryCode.Brazil:
            return 'Brasil'
    }
    throw new Error(`Unknown country code ${countyCode}`)
}

export function returnCountrySwitcherLabelByContentLanguage({ contentLanguageCode }: ParsedLocale) {
    switch (contentLanguageCode) {
        case Language.German:
            return 'Deutschland'
        case Language.BritishEnglish:
            return 'United Kingdom'
        case Language.French:
            return 'France'
        case Language.Polish:
            return 'Polska'
        case Language.Italian:
            return 'Italia'
        case Language.AmericanEnglish:
            return 'United States'
        case Language.Spanish:
            return 'España'
        case Language.ColombianSpanish:
            return 'Colombia'
        case Language.Turkish:
            return 'Türkiye'
        case Language.MexicanSpanish:
            return 'México'
        case Language.ArgentineSpanish:
            return 'Argentina'
        case Language.ChileanSpanish:
            return 'Chile'
        case Language.PeruvianSpanish:
            return 'Perú'
        case Language.Romanian:
            return 'România'
        case Language.Greek:
            return 'Ελλάδα'
        case Language.BrazilianPortuguese:
            return 'Brasil'
    }
    throw new Error(`Unknown language ${contentLanguageCode}`)
}

export function localizeNumber(number: number, language: Language): string {
    return number.toLocaleString(language)
}
