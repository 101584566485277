import { referrerScreenQueryKey } from 'lib/constants/referrerScreen'
import { EventOptions } from './analytics-service'

export const getReferrerScreen = (options: EventOptions): string | null => {
    if (options.referrerScreen) {
        return options.referrerScreen
    }

    // For server-side
    if (options.serverSideRequest?.url) {
        const url = new URL(options.serverSideRequest.url)
        const utmContent = url.searchParams.get(referrerScreenQueryKey)
        if (utmContent) {
            return utmContent
        }
    }

    // For client-side
    if (!options.isServerSide && typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search)
        const utmContent = urlParams.get(referrerScreenQueryKey)
        if (utmContent) {
            return utmContent
        }
    }

    return null
}
