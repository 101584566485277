import { CountryCode } from 'interfaces/Country'
import { Language } from 'interfaces/Language'
import { PrismicLanguage } from 'interfaces/PrismicLanguage'

export interface ParsedLocale {
    countryCode: CountryCode
    contentLanguageCode: Language
    interfaceLanguageCode: Language
    prismicLegalLanguage: PrismicLanguage
    prismicLanguage: PrismicLanguage
    strapiLanguage: Language
}

export enum Locale {
    GermanGermany = 'de',
    FrenchFrance = 'fr',
    EnglishUnitedKingdom = 'en-GB',
    PolishPoland = 'pl',
    ItalianItaly = 'it',
    EnglishUnitedStates = 'en',
    SpainSpanish = 'es',
    TurkeyTurkish = 'tr',
}

export function getLocales(): Record<string, ParsedLocale | undefined> {
    return locales
}

const locales: Record<string, ParsedLocale | undefined> = {
    de: {
        countryCode: CountryCode.Germany,
        contentLanguageCode: Language.German,
        interfaceLanguageCode: Language.German,
        prismicLegalLanguage: 'de-de',
        prismicLanguage: 'de-de',
        strapiLanguage: Language.German,
    },
    fr: {
        countryCode: CountryCode.France,
        contentLanguageCode: Language.French,
        interfaceLanguageCode: Language.French,
        prismicLegalLanguage: 'fr-fr',
        prismicLanguage: 'fr-fr',
        strapiLanguage: Language.French,
    },
    pl: {
        countryCode: CountryCode.Poland,
        contentLanguageCode: Language.Polish,
        interfaceLanguageCode: Language.Polish,
        prismicLegalLanguage: 'pl',
        prismicLanguage: 'pl',
        strapiLanguage: Language.Polish,
    },
    it: {
        countryCode: CountryCode.Italy,
        contentLanguageCode: Language.Italian,
        interfaceLanguageCode: Language.Italian,
        prismicLegalLanguage: 'it-it',
        prismicLanguage: 'it-it',
        strapiLanguage: Language.Italian,
    },
    'en-GB': {
        countryCode: CountryCode.UnitedKingdom,
        contentLanguageCode: Language.BritishEnglish,
        interfaceLanguageCode: Language.BritishEnglish,
        prismicLegalLanguage: 'en-gb',
        prismicLanguage: 'en-gb',
        strapiLanguage: Language.BritishEnglish,
    },
    en: {
        countryCode: CountryCode.UnitedStates,
        contentLanguageCode: Language.AmericanEnglish,
        interfaceLanguageCode: Language.AmericanEnglish,
        prismicLegalLanguage: 'en-us',
        prismicLanguage: 'en-us',
        strapiLanguage: Language.AmericanEnglish,
    },
    es: {
        countryCode: CountryCode.Spain,
        contentLanguageCode: Language.Spanish,
        interfaceLanguageCode: Language.Spanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
        strapiLanguage: Language.Spanish,
    },
    'es-MX': {
        countryCode: CountryCode.Mexico,
        contentLanguageCode: Language.MexicanSpanish,
        interfaceLanguageCode: Language.MexicanSpanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
        strapiLanguage: Language.Spanish,
    },
    'es-CO': {
        countryCode: CountryCode.Colombia,
        contentLanguageCode: Language.ColombianSpanish,
        interfaceLanguageCode: Language.ColombianSpanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
        strapiLanguage: Language.ColombianSpanish,
    },
    'es-PE': {
        countryCode: CountryCode.Peru,
        contentLanguageCode: Language.PeruvianSpanish,
        interfaceLanguageCode: Language.PeruvianSpanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
        strapiLanguage: Language.ColombianSpanish,
    },
    'es-CL': {
        countryCode: CountryCode.Chile,
        contentLanguageCode: Language.ChileanSpanish,
        interfaceLanguageCode: Language.ChileanSpanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
        strapiLanguage: Language.ColombianSpanish,
    },
    'es-AR': {
        countryCode: CountryCode.Argentina,
        contentLanguageCode: Language.ArgentineSpanish,
        interfaceLanguageCode: Language.ArgentineSpanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
        strapiLanguage: Language.ColombianSpanish,
    },
    tr: {
        countryCode: CountryCode.Turkey,
        contentLanguageCode: Language.Turkish,
        interfaceLanguageCode: Language.Turkish,
        prismicLegalLanguage: 'tr',
        prismicLanguage: 'tr',
        strapiLanguage: Language.Turkish,
    },
    ro: {
        countryCode: CountryCode.Romania,
        contentLanguageCode: Language.Romanian,
        interfaceLanguageCode: Language.Romanian,
        prismicLegalLanguage: 'ro-ro',
        prismicLanguage: 'ro-ro',
        strapiLanguage: Language.Romanian,
    },
    el: {
        countryCode: CountryCode.Greece,
        contentLanguageCode: Language.Greek,
        interfaceLanguageCode: Language.Greek,
        prismicLegalLanguage: 'el-gr',
        prismicLanguage: 'el-gr',
        strapiLanguage: Language.Greek,
    },
    'pt-BR': {
        countryCode: CountryCode.Brazil,
        contentLanguageCode: Language.BrazilianPortuguese,
        interfaceLanguageCode: Language.BrazilianPortuguese,
        prismicLegalLanguage: 'pt-br',
        prismicLanguage: 'pt-br',
        strapiLanguage: Language.BrazilianPortuguese,
    },
}

export function parseLocale(locale?: string): ParsedLocale {
    if (!locale) {
        throw new Error(`Locale not available.`)
    }

    const data = locales[locale]
    if (!data) {
        throw new Error(`Unknown locale '${locale}'`)
    }

    return data
}

export function localizeNumber(number: number, language: Language): string {
    return number.toLocaleString(language)
}
