import { ButtonText } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import styled, { css } from 'styled-components'
import _useTranslation from 'lib/hooks/_useTranslation'
import { redirectToAppDownloadUrlAsync } from 'lib/features/app-links'
import { getKnowToDownload } from 'lib/tracking/storage'
import { appDeepLinkKnow } from 'lib/features/app-deep-links'

const Container = styled.div<{ isDark?: boolean; backgroundColor?: string; fitContent?: boolean; height?: string }>`
    background: ${({ isDark }) => (isDark ? theme.colors.headlineDark : theme.colors.white)};
    display: flex;
    justify-content: center;
    border-radius: ${theme.borderRadius.message};
    padding: 5px 25px;
    place-items: center;
    grid-gap: 15px;
    cursor: pointer;
    min-height: ${({ height }) => height ?? '45px'};
    min-width: 185px;

    & svg {
        height: 25px;
    }

    .row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        svg {
            height: auto;
        }
    }

    ${({ backgroundColor }) =>
        backgroundColor
            ? css`
                  background-color: ${backgroundColor};
              `
            : ''};

    ${({ fitContent }) =>
        fitContent
            ? css`
                  width: fit-content;
                  min-width: auto;
              `
            : ''};

    @media (max-width: 750px) {
        padding: 5px 10px;
    }
`

interface Props {
    referrerScreen: string
    onClick?: () => void
    isDark?: boolean
    backgroundColor?: string
    downloadDeepLinkPath?: string
    fitContent?: boolean
    showOpenCTAText?: boolean
    icon?: JSX.Element
    buttonText?: string
    height?: string
}

const OpenTheAppDownloadButton = ({
    onClick,
    backgroundColor,
    isDark,
    downloadDeepLinkPath,
    referrerScreen,
    fitContent,
    showOpenCTAText,
    icon,
    buttonText,
    height,
}: Props) => {
    const { t } = _useTranslation('common')

    const onButtonClick = () => {
        let internalKnowDeepLinkPath = undefined
        const know = getKnowToDownload()
        if (know) {
            internalKnowDeepLinkPath = appDeepLinkKnow(know.uuid)
        }

        onClick && onClick()
        redirectToAppDownloadUrlAsync('dynamic', referrerScreen, downloadDeepLinkPath ?? internalKnowDeepLinkPath)
    }

    return (
        <span onClick={onButtonClick}>
            <Container
                className="button"
                backgroundColor={backgroundColor}
                isDark={isDark}
                fitContent={fitContent}
                height={height}
                data-nosnippet="true"
            >
                <div className="row">
                    <ButtonText color={isDark ? theme.colors.white : theme.colors.subtitleBlack} textAlign="center">
                        {buttonText ?? t(showOpenCTAText ? 'common/open' : 'common/openApp')}
                    </ButtonText>
                    {icon ?? null}
                </div>
            </Container>
        </span>
    )
}

export default OpenTheAppDownloadButton
