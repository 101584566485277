import { CountryCode } from 'interfaces/Country'
import { Currency } from 'interfaces/Currency'
import { CountryLanguageConfig } from 'lib/constants/countryLanguageConfig'

export const isExpansionContentCompetitionLive = ({
    expansionContestStartOn,
    expansionContestEndOn,
}: CountryLanguageConfig) => {
    if (!expansionContestStartOn) return false
    return (
        new Date() >= new Date(expansionContestStartOn) &&
        (!expansionContestEndOn || new Date() <= new Date(expansionContestEndOn))
    )
}

export function getCountryExpansionKnowTiers(countryCode: CountryCode) {
    switch (countryCode) {
        case CountryCode.Germany:
        case CountryCode.Switzerland:
        case CountryCode.Austria:
        case CountryCode.Poland:
        case CountryCode.France:
        case CountryCode.UnitedKingdom:
        case CountryCode.Italy:
        case CountryCode.UnitedStates:
        case CountryCode.Spain:
        case CountryCode.Mexico:
        case CountryCode.Argentina:
        case CountryCode.Chile:
        case CountryCode.Colombia:
        case CountryCode.Peru:
        case CountryCode.Turkey:
            return null
        case CountryCode.Greece:
            return [
                {
                    payoutAmount: 50,
                    knowsRequirement: '50+',
                    bottomLimit: 50,
                    topLimit: null,
                    currency: Currency.EUR,
                },
                {
                    payoutAmount: 40,
                    knowsRequirement: '36-50',
                    bottomLimit: 36,
                    topLimit: 51,
                    currency: Currency.EUR,
                },
                {
                    payoutAmount: 35,
                    knowsRequirement: '26-35',
                    bottomLimit: 26,
                    topLimit: 36,
                    currency: Currency.EUR,
                },
                {
                    payoutAmount: 30,
                    knowsRequirement: '16-25',
                    bottomLimit: 16,
                    topLimit: 26,
                    currency: Currency.EUR,
                },
                {
                    payoutAmount: 25,
                    knowsRequirement: '6-15',
                    bottomLimit: 6,
                    topLimit: 16,
                    currency: Currency.EUR,
                },
                {
                    payoutAmount: 20,
                    knowsRequirement: '1-5',
                    bottomLimit: 0,
                    topLimit: 6,
                    currency: Currency.EUR,
                },
            ]

        case CountryCode.Romania:
            return [
                {
                    payoutAmount: 200,
                    knowsRequirement: '50+',
                    bottomLimit: 50,
                    topLimit: null,
                    currency: Currency.RON,
                },
                {
                    payoutAmount: 180,
                    knowsRequirement: '36-50',
                    bottomLimit: 36,
                    topLimit: 51,
                    currency: Currency.RON,
                },
                {
                    payoutAmount: 140,
                    knowsRequirement: '26-35',
                    bottomLimit: 26,
                    topLimit: 36,
                    currency: Currency.RON,
                },
                {
                    payoutAmount: 120,
                    knowsRequirement: '16-25',
                    bottomLimit: 16,
                    topLimit: 26,
                    currency: Currency.RON,
                },
                {
                    payoutAmount: 100,
                    knowsRequirement: '6-15',
                    bottomLimit: 6,
                    topLimit: 16,
                    currency: Currency.RON,
                },
                {
                    payoutAmount: 80,
                    knowsRequirement: '1-5',
                    bottomLimit: 0,
                    topLimit: 6,
                    currency: Currency.RON,
                },
            ]
        case CountryCode.Brazil:
            return [
                {
                    payoutAmount: 160,
                    knowsRequirement: '50+',
                    bottomLimit: 50,
                    topLimit: null,
                    currency: Currency.BRL,
                },
                {
                    payoutAmount: 140,
                    knowsRequirement: '36-50',
                    bottomLimit: 36,
                    topLimit: 51,
                    currency: Currency.BRL,
                },
                {
                    payoutAmount: 120,
                    knowsRequirement: '26-35',
                    bottomLimit: 26,
                    topLimit: 36,
                    currency: Currency.BRL,
                },
                {
                    payoutAmount: 100,
                    knowsRequirement: '16-25',
                    bottomLimit: 16,
                    topLimit: 26,
                    currency: Currency.BRL,
                },
                {
                    payoutAmount: 80,
                    knowsRequirement: '6-15',
                    bottomLimit: 6,
                    topLimit: 16,
                    currency: Currency.BRL,
                },
                {
                    payoutAmount: 60,
                    knowsRequirement: '1-5',
                    bottomLimit: 0,
                    topLimit: 6,
                    currency: Currency.BRL,
                },
            ]

        default:
            throw new Error(`Unknown countryCode: ${countryCode}`)
    }
}
