import { ClientOnly } from 'components/elements/ClientOnly'
import { Body2 } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'

const List = styled.ul<{ position: { xOffset: number; yOffset: number } }>`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    place-content: center start;
    grid-auto-flow: column;
    grid-gap: 15px;
    height: 0;
    position: relative;
    ${({ position: { xOffset, yOffset } }) =>
        css`
            right: ${xOffset}px;
            bottom: ${yOffset}px;
        `}

    & li {
        cursor: pointer;
    }

    & li:hover svg {
        fill: var(--secondary-color);
    }

    & li svg {
        margin-right: 7px;
    }
`

const Dropdown = styled.li`
    cursor: pointer;
    border-radius: 10px;
    padding: 5px !important;
    margin: 0;
    z-index: ${theme.zIndex.FAB};
    position: absolute;
    & .dropdownToggle {
        display: grid;
        grid-template-columns: auto auto auto;
        place-items: center;
        margin-top: 3px;
    }

    & .dropdownContent {
        padding: 10px 20px 10px 20px;
        box-shadow: 0px 4px 16px 0px #0000001f;
        width: 220px;
        z-index: ${theme.zIndex.navBarMobileExpanded};
        cursor: auto;
        border-radius: ${theme.borderRadius.normal};
        background-color: ${theme.colors.white};
        @media (max-width: 750px) {
            top: 60px;
            right: 15px;
        }
    }

    & .dropdownContent ul {
        display: block;
        padding: 0;
    }

    & .dropdownContent li {
        padding: 15px 10px;
        .fakeLink {
            cursor: pointer;
        }
        .fakeLink,
        a {
            padding: 0 5px 0 10px;
        }
        display: block;
        margin: 0;
        :hover {
            background-color: ${theme.colors.violetBackground};
            border-radius: ${theme.borderRadius.huge};
        }
    }
`

type ListItem = {
    name: string
    to: string
    condition: boolean
    openInNewTab: boolean
    onClick?: () => void
}

interface Props {
    dropdownRef: React.RefObject<HTMLLIElement>
    open: boolean
    handleCloseDropdown: () => void
    listItems: ListItem[]
    position: {
        xOffset: number
        yOffset: number
    }
}

const DropDown = ({ dropdownRef, open, handleCloseDropdown, listItems, position }: Props) => {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const router = useRouter()

    const onOptionClicked = ({ onClick, to, openInNewTab }: ListItem) => {
        if (onClick) onClick()

        if (openInNewTab) {
            window.open(to, '_blank')
        } else {
            router.push(to)
        }
    }

    return (
        <List position={position}>
            <ClientOnly>
                {authenticatedUser ? (
                    <Dropdown ref={dropdownRef} className={`dropdown ${open ? 'open' : ''}`}>
                        {open ? (
                            <div className="dropdownContent">
                                <ul>
                                    {listItems.map((item, index) =>
                                        item.condition ? (
                                            <li key={index} onClick={() => onOptionClicked(item)}>
                                                <Body2
                                                    fontFamily="var(--font-inter)"
                                                    color={theme.colors.actionGray}
                                                    fontWeight={400}
                                                    className="fakeLink"
                                                >
                                                    {item.name}
                                                </Body2>
                                            </li>
                                        ) : null
                                    )}
                                </ul>
                            </div>
                        ) : null}
                    </Dropdown>
                ) : null}
            </ClientOnly>
        </List>
    )
}

export default DropDown
