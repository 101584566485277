import AppStoreFR from 'assets/know/app_store_fr.svg'
import GooglePlayFR from 'assets/know/google_play_fr.svg'
import AppStoreDE from 'assets/know/app_store_de.svg'
import GooglePlayDE from 'assets/know/google_play_de.svg'
import AppStoreEN from 'assets/know/app_store_en.svg'
import GooglePlayEN from 'assets/know/google_play_en.svg'
import AppStoreIT from 'assets/know/app_store_it.svg'
import GooglePlayIT from 'assets/know/google_play_it.svg'
import AppStorePL from 'assets/know/app_store_pl.svg'
import GooglePlayPL from 'assets/know/google_play_pl.svg'
import AppStoreTR from 'assets/know/app_store_tr.svg'
import GooglePlayTR from 'assets/know/google_play_tr.svg'
import AppStoreES from 'assets/know/app_store_es.svg'
import GooglePlayES from 'assets/know/google_play_es.svg'
import { ParsedLocale } from 'lib/constants/localization'
import { Language } from 'interfaces/Language'
import styled from 'styled-components'

const Wrapper = styled.div`
    cursor: pointer;
`

export const returnDownloadButton = ({ contentLanguageCode }: ParsedLocale, type: 'android' | 'ios') => {
    const isAndroid = type === 'android'
    switch (contentLanguageCode) {
        case Language.German:
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayDE />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStoreDE />
                </Wrapper>
            )
        case Language.AmericanEnglish:
        case Language.BritishEnglish:
        case Language.Greek:
        case Language.BrazilianPortuguese:
        case Language.Romanian: //TODO add Romanian and greek download buttons
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayEN />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStoreEN />
                </Wrapper>
            )
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.MexicanSpanish:
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayES />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStoreES />
                </Wrapper>
            )
        case Language.French:
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayFR />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStoreFR />
                </Wrapper>
            )
        case Language.Italian:
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayIT />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStoreIT />
                </Wrapper>
            )
        case Language.Polish:
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayPL />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStorePL />
                </Wrapper>
            )
        case Language.Turkish:
            return isAndroid ? (
                <Wrapper>
                    <GooglePlayTR />
                </Wrapper>
            ) : (
                <Wrapper>
                    <AppStoreTR />
                </Wrapper>
            )
        default:
            throw new Error(`Unsupported combination: type=${type}, language=${contentLanguageCode}`)
    }
}
