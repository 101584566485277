import { CountryCode } from 'interfaces/Country'
import { Language, LatinAmericanSpanish } from 'interfaces/Language'

export function languageToCountryCode(language: Language) {
    switch (language) {
        case Language.German:
            return CountryCode.Germany
        case Language.French:
            return CountryCode.France
        case Language.Polish:
            return CountryCode.Poland
        case Language.Italian:
            return CountryCode.Italy
        case Language.Spanish:
            return CountryCode.Spain
        case Language.BritishEnglish:
            return CountryCode.UnitedKingdom
        case Language.AmericanEnglish:
            return CountryCode.UnitedStates
        case Language.ColombianSpanish:
            return CountryCode.Colombia
        case Language.Turkish:
            return CountryCode.Turkey
        case Language.MexicanSpanish:
            return CountryCode.Mexico
        case Language.ArgentineSpanish:
            return CountryCode.Argentina
        case Language.ChileanSpanish:
            return CountryCode.Chile
        case Language.PeruvianSpanish:
            return CountryCode.Peru
        case Language.Romanian:
            return CountryCode.Romania
        case Language.Greek:
            return CountryCode.Greece
        case Language.BrazilianPortuguese:
            return CountryCode.Brazil
    }
    throw new Error(`Unknown language ${language}`)
}

export const getLocalizedContentLanguage = (language: typeof LatinAmericanSpanish | Language, countryCode: CountryCode) => {
    if (language === LatinAmericanSpanish) {
        switch (countryCode) {
            case CountryCode.Mexico:
                return Language.MexicanSpanish
            case CountryCode.Colombia:
                return Language.ColombianSpanish
            case CountryCode.Argentina:
                return Language.ArgentineSpanish
            case CountryCode.Peru:
                return Language.PeruvianSpanish
            case CountryCode.Chile:
                return Language.ChileanSpanish
            default:
                return Language.Spanish
        }
    }
    return language
}

export const getGeneralizedContentLanguage = (language: Language) => {
    switch (language) {
        case Language.MexicanSpanish:
        case Language.ColombianSpanish:
        case Language.ArgentineSpanish:
        case Language.PeruvianSpanish:
        case Language.ChileanSpanish:
            return LatinAmericanSpanish
        default:
            return language
    }
}
