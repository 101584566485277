import { Know } from 'interfaces/Know'
import { TrackingEvent } from 'interfaces/TrackingEvent'
import {
    generateUserTrackingSnippet,
    generateUserTrackingSnippetUnauthenticated,
    generateWebsiteTrackingSnippet,
    generateKnowTrackingSnippet,
    generateDeviceTrackingSnippet,
    generatePageTrackingSnippet,
    generateChatTrackingSnippet,
    generateChatMessageTrackingSnippet,
    generateSessionSnippet,
    generateSearchTrackingSnippet,
    FeedEventOptions,
    SearchOptions,
    generateFeedTrackingSnippet,
    AiChatOptions,
    generateAiChatTrackingSnippet,
} from 'interfaces/TrackingSnippets'
import store from 'redux/store'
import AnalyticsService from 'services/AnalyticsService'
import attribution from './attribution/attribution'
import config from '../config'
import { getActiveExperimentsVariants } from '../features/activeExperiments'
import { ChatMessage, UserChat } from 'interfaces/UserChat'
import { uuidv4 } from 'lib/tracking/attribution/uuid'
import { NextRequest } from 'next/server'
import { getUTCTimezoneOffset } from './userTimezone'
import { getReferrerScreen } from './analyticsReferrerScreen'

// eslint-disable-next-line
declare const fbq: any

declare global {
    interface Window {
        // eslint-disable-next-line
        gtag: any
        // eslint-disable-next-line
        knowunityCookieInitialized: any
    }
}

export interface EventOptions {
    value?: string | null
    valueId?: number | null
    referrerScreen?: string
    know?: Know
    useBeacon?: boolean
    chat?: UserChat
    chatMessage?: ChatMessage
    chatPartnerUuid?: string | null
    search?: SearchOptions
    feed?: FeedEventOptions
    isServerSide?: boolean
    aiChat?: AiChatOptions
    sessionSource?: string
    serverSideRequest?: NextRequest
}

export function trackEvent(type: string, options: EventOptions) {
    const data = getEventData(type, options)

    if (config.getEnvironment() === config.Environment.Local) {
        console.warn('Skip sending analytics event locally', data)
        return
    }

    if (options.isServerSide) {
        AnalyticsService.trackEventServerSide(data)
    } else if (options.useBeacon) {
        AnalyticsService.trackEventBeacon(data)
    } else {
        AnalyticsService.trackEvent(data)
    }
}

const getEventData = (type: string, options: EventOptions): TrackingEvent => {
    const attributionData = attribution.getAttribution()
    const isServerSide = options?.isServerSide || typeof window === 'undefined'
    const user = isServerSide ? null : store().getState().user

    const experiments = isServerSide ? [] : [...getActiveExperimentsVariants()]

    let data: TrackingEvent = {
        name: type,
        value: options.value || null,
        valueId: options.valueId ? Math.ceil(options.valueId) : null,
        referrerScreen: getReferrerScreen(options),
        uuid: uuidv4(),
        deviceSnippet: generateDeviceTrackingSnippet(),
        deviceTimeZoneOffset: getUTCTimezoneOffset({ showAsDifference: true }),
        platform: 'web' as const,
        appVersion: '1.0.0',
        appBuildNumber: null,

        aiChat: options?.aiChat ? generateAiChatTrackingSnippet(options.aiChat) : null,
        activeExperiments: experiments,
        search: options?.search ? generateSearchTrackingSnippet(options.search) : null,
        feed: options?.feed ? generateFeedTrackingSnippet(options.feed) : null,
        know: options.know ? generateKnowTrackingSnippet(options.know) : null,
        chat: options.chat ? generateChatTrackingSnippet(options.chat) : null,
        chatMessage: options.chatMessage
            ? generateChatMessageTrackingSnippet(options.chatMessage, options.chatPartnerUuid)
            : null,
        page: generatePageTrackingSnippet(options.serverSideRequest),
        user: null,
        session: null,
        web: generateWebsiteTrackingSnippet(options.serverSideRequest),
    }
    if (!isServerSide) {
        data = {
            ...data,
            user: user ? generateUserTrackingSnippet(user) : generateUserTrackingSnippetUnauthenticated(attributionData),
            session: generateSessionSnippet(attributionData),
        }
    }
    return data
}
