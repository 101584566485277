import { CountryCode } from 'interfaces/Country'
import { Environment, getEnvironment } from 'lib/config'

export const countryDomains = {
    'knowunity.de': CountryCode.Germany,
    'knowunity.fr': CountryCode.France,
    'knowunity.pl': CountryCode.Poland,
    'knowunity.it': CountryCode.Italy,
    'knowunity.co.uk': CountryCode.UnitedKingdom,
    'knowunity.com': CountryCode.UnitedStates,
    'knowunity.es': CountryCode.Spain,
    'knowunity.co': CountryCode.Colombia,
    'knowunity.com.tr': CountryCode.Turkey,
    'knowunity.com.mx': CountryCode.Mexico,
    'knowunity.ro': CountryCode.Romania,
    'knowunity.gr': CountryCode.Greece,
    'knowunity.com.ar': CountryCode.Argentina,
    'knowunity.cl': CountryCode.Chile,
    'knowunity.pe': CountryCode.Peru,
    'knowunity.com.br': CountryCode.Brazil,
}

export const countryDevDomains = {
    'de.knowunity.dev': CountryCode.Germany,
    'fr.knowunity.dev': CountryCode.France,
    'pl.knowunity.dev': CountryCode.Poland,
    'it.knowunity.dev': CountryCode.Italy,
    'gb.knowunity.dev': CountryCode.UnitedKingdom,
    'us.knowunity.dev': CountryCode.UnitedStates,
    'es.knowunity.dev': CountryCode.Spain,
    'co.knowunity.dev': CountryCode.Colombia,
    'tr.knowunity.dev': CountryCode.Turkey,
    'mx.knowunity.dev': CountryCode.Mexico,
    'ro.knowunity.dev': CountryCode.Romania,
    'gr.knowunity.dev': CountryCode.Greece,
    'pe.knowunity.dev': CountryCode.Peru,
    'cl.knowunity.dev': CountryCode.Chile,
    'ar.knowunity.dev': CountryCode.Argentina,
    'br.knowunity.dev': CountryCode.Brazil,
}

export const getLocalizedCountryOfOrigin = (countryCode: CountryCode) => {
    switch (countryCode) {
        case CountryCode.Austria:
        case CountryCode.Switzerland:
            return CountryCode.Germany
        default:
            return countryCode
    }
}

export function getDomainByCountryCode(countryCode: CountryCode): string | null {
    const domainSource = getEnvironment() === Environment.Prod ? countryDomains : countryDevDomains
    const localizedKnowerCountryOfOrigin = getLocalizedCountryOfOrigin(countryCode)
    const domain = Object.entries(domainSource).find(([_, v]) => v === localizedKnowerCountryOfOrigin)
    return domain ? domain[0] : null
}
