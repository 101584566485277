import { GetServerSidePropsContext } from 'next'
import { parseCookies, setCookie } from 'nookies'

const ActiveExperimentsKEY = 'ACTIVE_EXPERIMENTS'

export type ActiveExperiments = Record<ExperimentKey, ExperimentVariant>

export enum ExperimentKey {
    test = 'TEST',
    showScrollableBreadcrumbs = 'SHOW_SCROLLABLE_BREADCRUMBS',
    showVisualTrustElements = 'SHOW_VISUAL_TRUST_ELEMENTS',
    showDarkThemePreventAccessDialog = 'SHOW_DARK_THEME_PREVENT_ACCESS_DIALOG',
    reduceMobileSummaryLength = 'REDUCE_MOBILE_SUMMARY_LENGTH',
    showDesktopUSPBanner = 'SHOW_DESKTOP_USP_BANNER',
}

export enum ExperimentVariant {
    testBaseline = 'TEST_0',
    testVariant = 'TEST_1',
    showScrollableBreadcrumbsVariant0 = 'SHOW_SCROLLABLE_BREADCRUMBS_VARIANT_0',
    showScrollableBreadcrumbsVariant1 = 'SHOW_SCROLLABLE_BREADCRUMBS_VARIANT_1',
    showVisualTrustElementsVariant0 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_V1_0',
    showVisualTrustElementsVariant1 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_V1_1',
    showVisualTrustElementsVariant2 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_V1_2',
    showVisualTrustElementsVariant3 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_V1_3',
    showDarkThemePreventAccessDialogVariant0 = 'SHOW_DARK_THEME_PREVENT_ACCESS_DIALOG_VARIANT_V1_0', // baseline
    showDarkThemePreventAccessDialogVariant1 = 'SHOW_DARK_THEME_PREVENT_ACCESS_DIALOG_VARIANT_V1_1', // dark theme
    reduceMobileSummaryLengthVariant0 = 'REDUCE_MOBILE_SUMMARY_LENGTH_VARIANT_0',
    reduceMobileSummaryLengthVariant1 = 'REDUCE_MOBILE_SUMMARY_LENGTH_VARIANT_1',
    showDesktopUSPBannerVariant0 = 'SHOW_DESKTOP_USP_BANNER_VARIANT_0',
    showDesktopUSPBannerVariant1 = 'SHOW_DESKTOP_USP_BANNER_VARIANT_1',
}

function getRandomVariant(variants: ExperimentVariant[]): ExperimentVariant {
    return variants[Math.floor(Math.random() * variants.length)]
}

function getStoredVariant(storedVariants: string[], variants: ExperimentVariant[]): ExperimentVariant | null {
    for (const variant of variants) {
        if (storedVariants.includes(variant)) {
            return variant
        }
    }

    return null
}

function getExperimentVariant(possibleVariants: ExperimentVariant[], storedVariants: string[]): ExperimentVariant {
    const storedVariant = getStoredVariant(storedVariants, possibleVariants)
    if (storedVariant !== null) {
        return storedVariant
    } else {
        return getRandomVariant(possibleVariants)
    }
}

export function getActiveExperiments(ctx?: GetServerSidePropsContext): ActiveExperiments {
    const storedVariants = getActiveExperimentsStore(ctx)

    const activeExperiments: ActiveExperiments = {
        TEST: getExperimentVariant([ExperimentVariant.testBaseline, ExperimentVariant.testVariant], storedVariants),
        SHOW_SCROLLABLE_BREADCRUMBS: getExperimentVariant(
            [ExperimentVariant.showScrollableBreadcrumbsVariant0, ExperimentVariant.showScrollableBreadcrumbsVariant1],
            storedVariants
        ),
        SHOW_VISUAL_TRUST_ELEMENTS: getExperimentVariant(
            [
                ExperimentVariant.showVisualTrustElementsVariant0,
                ExperimentVariant.showVisualTrustElementsVariant1,
                ExperimentVariant.showVisualTrustElementsVariant2,
                ExperimentVariant.showVisualTrustElementsVariant3,
            ],
            storedVariants
        ),
        REDUCE_MOBILE_SUMMARY_LENGTH: getExperimentVariant(
            [ExperimentVariant.reduceMobileSummaryLengthVariant0, ExperimentVariant.reduceMobileSummaryLengthVariant1],
            storedVariants
        ),
        SHOW_DARK_THEME_PREVENT_ACCESS_DIALOG: getExperimentVariant(
            [
                ExperimentVariant.showDarkThemePreventAccessDialogVariant0,
                ExperimentVariant.showDarkThemePreventAccessDialogVariant1,
            ],
            storedVariants
        ),
        SHOW_DESKTOP_USP_BANNER: getExperimentVariant(
            [ExperimentVariant.showDesktopUSPBannerVariant0, ExperimentVariant.showDesktopUSPBannerVariant1],
            storedVariants
        ),
    }

    storeActiveExperiments(Object.values(activeExperiments), ctx)

    return activeExperiments
}

export function getActiveExperimentsVariants() {
    const activeExperiments = getActiveExperiments()
    return Object.values(activeExperiments)
}

function isValidExperiment(experiment: ExperimentVariant, possibleVariants: ExperimentVariant[]): boolean {
    return possibleVariants.includes(experiment)
}

function cachedExperimentsAreValid(cachedExperiments: ActiveExperiments | null): boolean {
    if (!cachedExperiments) return false

    const allPossibleVariants = Object.values(ExperimentVariant)
    return Object.values(cachedExperiments).every((variant) => isValidExperiment(variant, allPossibleVariants))
}

// Active Experiments
export function getActiveExperimentsStore(ctx?: GetServerSidePropsContext): string[] {
    const cookies = parseCookies(ctx)
    const item = cookies[ActiveExperimentsKEY]
    if (!item) {
        return []
    }
    return item.split(',')
}

export const storeActiveExperiments = (experiments: string[], ctx?: GetServerSidePropsContext) => {
    const stringifiedValue = experiments.join(',')
    setCookie(ctx, ActiveExperimentsKEY, stringifiedValue, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
    })
}
