import SearchRecommendations from 'components/elements/SearchRecommendations'
import { KnowFormat } from 'interfaces/Know'
import { SearchSuggestion } from 'interfaces/Search'
import { appSearchRouteOptions, searchResultRouteOptions } from 'lib/constants/routes'
import { isDeviceMobile } from 'lib/features/deviceInfo'
import _useTranslation from 'lib/hooks/_useTranslation'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'

interface Props {
    referrerScreen: string
    knowFormat?: KnowFormat
    isLocatedOnHomepage?: boolean
    hideDialogLayout?: boolean
    onSearchOption?: () => void
    highlight?: boolean
    isDarkTheme?: boolean
}

const SearchBarRedirect = ({
    referrerScreen,
    knowFormat,
    isLocatedOnHomepage,
    hideDialogLayout,
    highlight,
    onSearchOption,
    isDarkTheme,
}: Props) => {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const router = useRouter()
    const { t: tCommon } = _useTranslation('common')
    const isMobile = isDeviceMobile()

    const redirectToWebapp = authenticatedUser && !isMobile

    const onSearchOptionClicked = (searchSuggestion: SearchSuggestion) => {
        const { text, subject } = searchSuggestion
        const searchKnowRoute = searchResultRouteOptions(text, subject?.id, { referrerScreen })
        const webappSearchRoute = appSearchRouteOptions(text, subject?.id, { referrerScreen, knowFormat })

        router.push(redirectToWebapp ? webappSearchRoute : searchKnowRoute)
        onSearchOption &&
            setTimeout(() => {
                onSearchOption()
            }, 1000)
    }

    return (
        <SearchRecommendations
            isSmall={!isLocatedOnHomepage}
            placeholder={
                isLocatedOnHomepage
                    ? tCommon('common/searchHomepageLabel')
                    : tCommon('common/searchTopicsAndSubjectsButtonLabel')
            }
            onSuggestionClicked={onSearchOptionClicked}
            origin={referrerScreen}
            hideDialogLayout={hideDialogLayout}
            referrerScreen={referrerScreen}
            highlight={isLocatedOnHomepage || highlight}
            isDarkTheme={isDarkTheme}
        />
    )
}

export default SearchBarRedirect
